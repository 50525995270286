export const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1);

export const getLinkWithoutEndSlash = (link: string): string => {
  return link.slice(0, link.length - 1);
};

export const getTitleFromLink = (link: string): string => {
  return link
    .split('-')
    .map((word) => capitalize(word))
    .join(' ');
};
