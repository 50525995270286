import {
  INestedSitemapPage,
  INestedSitemapPages,
  IParsedSitemapPages,
  ITransformedSitemapPages,
} from './models';

import { getLinkWithoutEndSlash, getTitleFromLink } from './helpers';

const parseSitemapPages = (
  pages: SitemapTypes.IIncomingSitemapPagesObject
): IParsedSitemapPages => {
  const parsedSitemapPages: IParsedSitemapPages = [];

  Object.keys(pages).forEach((pageNode: string) => {
    if (!pages[pageNode]?.nodes) {
      if (pages[pageNode]?.link) {
        const linkWithoutEndSlash = getLinkWithoutEndSlash(pages[pageNode].link!);

        parsedSitemapPages.push({
          link: linkWithoutEndSlash,
          name: pages[pageNode]?.pageNameReserved || pages[pageNode]?.pageName,
        });
      }
    } else {
      pages[pageNode]?.nodes!.forEach((page) => {
        if (page?.link) {
          const linkWithoutEndSlash = getLinkWithoutEndSlash(page.link);

          parsedSitemapPages.push({
            link: linkWithoutEndSlash,
            name: page?.pageNameReserved || page?.pageName,
          });
        }
      });
    }
  });

  return parsedSitemapPages;
};

const transformSitemapPages = (
  parsedSitemapPages: IParsedSitemapPages,
  langFromUrl
): ITransformedSitemapPages => {
  return (parsedSitemapPages.map((pageNode) => {
    if (pageNode.link === '') {
      return; // skip home page
    }

    const urlParts = pageNode.link
      .replace(`/${langFromUrl}/`, '')
      .split('/')
      .filter((str) => str);
    const urlPartsLocalised = pageNode.link.split('/').filter((str) => str);
    const urlNestingLevel = urlParts.length;
    const tailLink = urlParts[urlNestingLevel - 1];
    const parentUrlNestingLevel = urlNestingLevel - 1;

    return {
      title: pageNode.name || getTitleFromLink(tailLink),
      link: pageNode.link,
      parentLink: parentUrlNestingLevel
        ? `/${urlPartsLocalised.slice(0, urlPartsLocalised.length - 1).join('/')}`
        : null,
      depth: urlNestingLevel,
    };
  }) as ITransformedSitemapPages).filter((page) => page);
};

export const createNestedSitemapPages = (
  allPages: ITransformedSitemapPages,
  parentLinkName: null | string = null
) => {
  const nestedPages: INestedSitemapPages = [];
  allPages
    .filter((page) => page.parentLink === parentLinkName)
    .map((page) => {
      const nestedPage: INestedSitemapPage = {
        title: page.title,
        link: `${page.link}/`,
        depth: page.depth,
        children: [],
      };
      nestedPage.children = createNestedSitemapPages(allPages, page.link);

      return nestedPages.push(nestedPage);
    });

  return nestedPages;
};

export const getNestedSitemapPages = (
  pages: SitemapTypes.IIncomingSitemapPagesObject,
  langFromUrl
): INestedSitemapPages => {
  const parsedSitemapPages = parseSitemapPages(pages);
  const transformedSitemapPages = transformSitemapPages(parsedSitemapPages, langFromUrl);

  return createNestedSitemapPages(transformedSitemapPages);
};

export const getNestedSymptomsPages = (
  navItems: HeaderNavigationTypes.INavItem[]
): INestedSitemapPages =>
  navItems
    .filter(({ properties }) => properties.items)
    .map((curItem) => {
      const nestedItem = curItem.properties.items?.map(
        ({ properties: { dropdown, title, pageNameReserved } }) => ({
          title: pageNameReserved || title,
          children: dropdown.map(
            ({ properties: { simpleLink, pageNameReserved: dropdownPageNameReserved } }) => ({
              title: dropdownPageNameReserved || simpleLink[0].name,
              link: simpleLink[0].url,
              depth: 3,
            })
          ),
          depth: 2,
        })
      );

      return {
        title: curItem.properties.pageNameReserved || curItem.properties.title || '',
        children:
          (nestedItem?.[0].title
            ? nestedItem
            : nestedItem?.[0].children.map((child) => ({ ...child, depth: 2 }))) || [],
        depth: 1,
      };
    });

export const getNestedCustomPages = (siteMapLink: TCustomMapLink[]) =>
  siteMapLink?.map((curItem) => {
    const nestedItem = curItem.properties?.siteMapDropdownItem?.[0].properties.siteMapLink.map(
      ({ properties: { siteMapDropdownItem, title, linkPath } }) => ({
        title: linkPath?.[0].name || title,
        link: linkPath?.[0].url,
        children: siteMapDropdownItem?.[0].properties.siteMapLink.map(({ properties }) => ({
          title: properties.linkPath?.[0].name || properties.title,
          link: properties.linkPath?.[0].url,
          depth: 3,
        })),
        depth: 2,
      })
    );

    return {
      title: curItem.properties.linkPath?.[0].name || curItem.properties.title || '',
      link: curItem.properties.linkPath?.[0].url,
      children:
        (nestedItem?.[0].title
          ? nestedItem
          : nestedItem?.[0].children.map((child) => ({ ...child, depth: 2 }))) || [],
      depth: 1,
    };
  });
